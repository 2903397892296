import React, { useState, useRef, useCallback, useEffect } from 'react';















import { auth } from '../firebase';















import { signOut, sendPasswordResetEmail, deleteUser } from 'firebase/auth';















import { FaMicrophone, FaStop, FaUser, FaSpinner } from 'react-icons/fa';















import Modal from './Modal';















import NewNoteModal from './NewNoteModal';















import RecordingModal from './RecordingModal';















import { getDoc, updateDoc, doc, setDoc, serverTimestamp } from 'firebase/firestore';















import { db } from '../firebase';































function Header({ user, openAuthModal }) {















  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);















  const [openaiKey, setOpenaiKey] = useState('');















  const [isSaving, setIsSaving] = useState(false);















  const [isRecording, setIsRecording] = useState(false);















  const [isNewNoteModalOpen, setIsNewNoteModalOpen] = useState(false);















  const [isRecordingModalOpen, setIsRecordingModalOpen] = useState(false);















  const [audioBlob, setAudioBlob] = useState(null);















  const [recordingTime, setRecordingTime] = useState(0);















  const mediaRecorderRef = useRef(null);















  const chunksRef = useRef([]);















  const timerRef = useRef(null);















  const [message, setMessage] = useState('');































  useEffect(() => {















    const fetchApiKey = async () => {















      if (user) {















        const userDoc = await getDoc(doc(db, 'users', user.uid));















        if (userDoc.exists() && userDoc.data().openaiKey) {















          setOpenaiKey(userDoc.data().openaiKey);















        }















      }















    };















    fetchApiKey();















  }, [user]);































  const handleSaveApiKey = async () => {















    if (!user) return;















    















    setIsSaving(true);















    setMessage('');































    try {















      const userRef = doc(db, 'users', user.uid);















      const userDoc = await getDoc(userRef);















      if (userDoc.exists()) {















        await updateDoc(userRef, {















          openaiKey: openaiKey















        });















      } else {















        await setDoc(userRef, {















          openaiKey: openaiKey,















          email: user.email,















          createdAt: serverTimestamp()















        });















      }















      setMessage('API 키가 성공적으로 저장되었습니다.');















      setTimeout(() => setMessage(''), 3000);















    } catch (error) {















      console.error('Error saving API key:', error);















      setMessage('API 키 저장 중 오류가 발생했습니다.');















    } finally {















      setIsSaving(false);















    }















  };































  const openProfileModal = useCallback(() => {















    setIsProfileModalOpen(true);















  }, []);































  const closeProfileModal = useCallback(() => {















    setIsProfileModalOpen(false);















  }, []);































  const handleSignOut = useCallback(() => {















    signOut(auth).catch((error) => {















      console.error('Error signing out: ', error);















    });















  }, []);































  const startRecording = useCallback(async () => {















    try {















      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });















      mediaRecorderRef.current = new MediaRecorder(stream);















      chunksRef.current = [];















      















      mediaRecorderRef.current.ondataavailable = (event) => {















        if (event.data.size > 0) {















          chunksRef.current.push(event.data);















        }















      };















      















      mediaRecorderRef.current.onstop = () => {















        const blob = new Blob(chunksRef.current, { type: 'audio/wav' });















        setAudioBlob(blob);















        setIsNewNoteModalOpen(true);















        setIsRecordingModalOpen(false);















      };















      















      mediaRecorderRef.current.start();















      setIsRecording(true);















      setIsRecordingModalOpen(true);















      setRecordingTime(0);















      timerRef.current = setInterval(() => {















        setRecordingTime((prevTime) => prevTime + 1);















      }, 1000);















    } catch (error) {















      console.error('Error accessing microphone:', error);















    }















  }, []);































  const stopRecording = useCallback(() => {















    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {















      mediaRecorderRef.current.stop();















      setIsRecording(false);















      clearInterval(timerRef.current);















    }















  }, []);































  const toggleRecording = useCallback(() => {















    if (isRecording) {















      stopRecording();















    } else {















      startRecording();















    }















  }, [isRecording, startRecording, stopRecording]);































  const openNewNoteModal = useCallback(() => {















    setIsNewNoteModalOpen(true);















    setAudioBlob(null); // 새로운 모달을 열 때 audioBlob을 초기화















  }, []);































  const closeNewNoteModal = useCallback(() => {















    setIsNewNoteModalOpen(false);















    setAudioBlob(null); // 모달을 닫을 때 audioBlob을 초기화















  }, []);































  const formatTime = useCallback((time) => {















    const minutes = Math.floor(time / 60);















    const seconds = time % 60;















    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;















  }, []);































  const handlePasswordReset = useCallback(async () => {















    if (!user || !user.email) {















      setMessage('이메일 주소를 찾을 수 없습니다.');















      return;















    }















    try {















      await sendPasswordResetEmail(auth, user.email);















      setMessage('비밀번호 재설정 이메일을 발송했습니다. 이메일을 확인해주세요.');















    } catch (error) {















      console.error('Error sending password reset email:', error);















      setMessage('비밀번호 재설정 이메일 발송에 실패했습니다. 다시 시도해주세요.');















    }















  }, [user]);































  const handleDeleteAccount = useCallback(async () => {















    if (!user) return;















    if (window.confirm('정말로 계정을 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.')) {















      try {















        await deleteUser(user);















        setMessage('계정이 성공적으로 삭제되었습니다.');















        closeProfileModal();















      } catch (error) {















        console.error('Error deleting user account:', error);















        setMessage('계정 삭제에 실패했습니다. 다시 로그인 후 시도해주세요.');















      }















    }















  }, [user, closeProfileModal]);































  return (















    <header className="bg-white border-b border-gray-200 shadow-sm">















      <div className="w-full mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">















        <h1 className="text-xl font-bold text-gray-800">상담 라이브러리</h1>















        <div className="flex items-center">















          {user ? (















            <>















              <button 















                onClick={openNewNoteModal}















                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-3xl mr-2"















              >















                새 상담 카드















              </button>















              <button 















                onClick={toggleRecording} 















                className={`flex items-center justify-center w-10 h-10 rounded-full ${isRecording ? 'bg-red-500 hover:bg-red-600' : 'bg-gray-500 hover:bg-gray-600'} text-white`}















              >















                {isRecording ? <FaStop className="text-lg" /> : <FaMicrophone className="text-lg" />}















              </button>















              <button 















                onClick={openProfileModal} 















                className="w-10 h-10 bg-gray-200 hover:bg-gray-300 text-gray-600 rounded-full flex items-center justify-center ml-2"















              >















                <FaUser />















              </button>















            </>















          ) : (















            <button 















              onClick={openAuthModal}















              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"















            >















              로그인















            </button>















          )}















        </div>















      </div>































      {isProfileModalOpen && user && (















        <Modal isOpen={isProfileModalOpen} onClose={closeProfileModal} title="프로필 수정" width="w-[400px]">















          <div className="p-6 space-y-4">















            <div>















              <label htmlFor="email" className="block text-sm font-medium text-gray-700">















                이메일















              </label>















              <input















                type="email"















                name="email"















                id="email"















                value={user.email || ''}















                readOnly















                disabled















                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm bg-gray-100 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"















              />















            </div>































            <div>















              <label className="block text-sm font-medium text-gray-700">















                OpenAI API Key















              </label>















              <input















                type="password"















                value={openaiKey}















                onChange={(e) => setOpenaiKey(e.target.value)}















                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"















                placeholder="sk-..."















              />















              <p className="mt-1 text-xs text-gray-500">















                AI 리포트 생성을 위해 OpenAI API 키가 필요합니다.















              </p>















            </div>































            <button 















              onClick={handleSaveApiKey}















              disabled={isSaving}















              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded disabled:opacity-50"















            >















              {isSaving ? (















                <span className="flex items-center justify-center">















                  <FaSpinner className="animate-spin mr-2" />















                  저장 중...















                </span>















              ) : (















                'API 키 저장'















              )}















            </button>































            <button 















              onClick={handlePasswordReset}















              className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"















            >















              비밀번호 재설정 이메일 보내기















            </button>































            <button 















              onClick={handleSignOut} 















              className="w-full bg-pink-500 hover:bg-pink-600 text-white font-bold py-2 px-4 rounded"















            >















              로그아웃















            </button>































            <div className="flex justify-between items-center pt-2">















              <button 















                onClick={handleDeleteAccount} 















                className="text-red-500 hover:text-red-700 text-xs"















              >















                회원 탈퇴















              </button>















            </div>































            {message && (















              <div className={`text-sm text-center p-2 rounded ${















                message.includes('성공') ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'















              }`}>















                {message}















              </div>















            )}















          </div>















        </Modal>















      )}































      <RecordingModal















        isOpen={isRecordingModalOpen}















        onClose={stopRecording}















        recordingTime={recordingTime}















        formatTime={formatTime}















      />































      {isNewNoteModalOpen && (















        <NewNoteModal















          isOpen={isNewNoteModalOpen}















          onClose={closeNewNoteModal}















          user={user}















          initialAudioBlob={audioBlob}















        />















      )}















    </header>















  );















}































export default Header;






























