import React, { useState } from 'react';
import Modal from './Modal';
import { FaSpinner, FaDownload, FaCopy } from 'react-icons/fa';
import * as XLSX from 'xlsx';

function AiReportModal({ isOpen, onClose, content, isLoading }) {
  const [copyMessage, setCopyMessage] = useState('');

  // 정렬된 상담 기록을 반환하는 함수
  const getSortedContent = () => {
    return content
      .split('\n')
      .filter(line => line.trim())
      .sort((a, b) => {
        const dateA = a.match(/\d{4}\.\d{2}\.\d{2}\./) ? new Date(a.match(/\d{4}\.\d{2}\.\d{2}\./)[0].split('.').join('-')) : new Date(0);
        const dateB = b.match(/\d{4}\.\d{2}\.\d{2}\./) ? new Date(b.match(/\d{4}\.\d{2}\.\d{2}\./)[0].split('.').join('-')) : new Date(0);
        return dateA - dateB;
      });
  };

  const handleCopy = async (text, type) => {
    try {
      // 전체 복사인 경우 정렬된 내용을 사용
      const contentToCopy = type === '전체 내용' 
        ? getSortedContent().join('\n')
        : text;

      await navigator.clipboard.writeText(contentToCopy);
      setCopyMessage(`${type}이(가) 클립보드에 복사되었습니다!`);
      setTimeout(() => setCopyMessage(''), 2000);
    } catch (err) {
      console.error('클립보드 복사 실패:', err);
    }
  };

  const downloadExcel = () => {
    // 정렬된 내용을 사용하여 엑셀 데이터 생성
    const data = getSortedContent()
      .map(line => {
        const dateMatch = line.match(/(\d{4}\.\d{2}\.\d{2}\.)(.+)/);
        if (dateMatch) {
          return {
            '날짜': dateMatch[1],
            '상담내용': dateMatch[2].trim()
          };
        }
        return null;
      })
      .filter(item => item);

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "상담기록");
    XLSX.writeFile(wb, "상담기록_AI분석.xlsx");
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="NEIS 입력용 AI 상담 분석 리포트" width="w-[800px]">
      <div className="p-6">
        <div className="mb-4 text-sm text-gray-600 bg-green-50 p-2 rounded-lg text-center">
          날짜와 상담 내용을 각각 클릭하여 개별적으로 복사할 수 있습니다.
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center h-40">
            <FaSpinner className="animate-spin text-blue-500 text-2xl" />
          </div>
        ) : (
          <div className="space-y-4">
            <div className="whitespace-pre-wrap rounded-lg max-h-[600px] overflow-y-auto">
              {getSortedContent().map((line, index) => {
                if (!line.trim()) return null;
                const dateMatch = line.match(/(\d{4}\.\d{2}\.\d{2}\.)(.+)/);
                if (dateMatch) {
                  return (
                    <div key={index} className="mb-4 bg-gray-50 p-4 rounded-lg">
                      <div className="flex items-start space-x-4">
                        <div
                          onClick={() => handleCopy(dateMatch[1], '날짜')}
                          className="cursor-pointer hover:bg-blue-50 px-3 py-1 rounded min-w-[120px] text-blue-600 font-semibold"
                        >
                          {dateMatch[1]}
                        </div>
                        <div
                          onClick={() => handleCopy(dateMatch[2].trim(), '상담 내용')}
                          className="cursor-pointer hover:bg-gray-100 px-3 py-1 rounded flex-1"
                        >
                          {dateMatch[2].trim()}
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <div className="flex justify-end items-center mt-4">
              {copyMessage && (
                <div className="text-sm text-green-600 bg-green-50 px-3 py-1 rounded mr-auto">
                  {copyMessage}
                </div>
              )}
              <div className="flex space-x-2">
                <button
                  onClick={downloadExcel}
                  className="flex items-center space-x-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                >
                  <FaDownload />
                  <span>엑셀 다운로드</span>
                </button>
                <button
                  onClick={() => handleCopy(content, '전체 내용')}
                  className="flex items-center space-x-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                  <FaCopy />
                  <span>전체 복사</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default AiReportModal; 


